import React from 'react'
import { Container, Row } from 'react-awesome-styled-grid'
import { useQuery } from 'react-query'
import Bugsnag from '@bugsnag/js'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { fetchCategoryPosts, fetchFeaturedPosts, fetchFirstBlockPosts, fetchRecentPosts } from '~/api'
import MobileAd from '~/components/ads/MobileAd'
import CustomCategoryPosts from '~/components/CustomCategoryPosts'
import FeaturedItems from '~/components/FeaturedItems'
import HomeHead from '~/components/HomeHead'
import InfinitePosts from '~/components/InfinitePosts'
import Layout from '~/components/Layout'
import NewsletterSubscribe from '~/components/NewsletterSubscribe'
import ResponsivePostGrid from '~/components/ResponsivePostGrid'
import { DEFAULT_CUSTOM_CATEGORY_SLUG } from '~/helpers/constants'
import { isMobileDeviceType, slicePosts } from '~/helpers/utils'

/**
 * getServerSideProps
 */

export const getServerSideProps = async ({ req }) => {
  const isMobile = isMobileDeviceType(req)
  try {
    const [initialFeaturedPosts, initialCategoryPosts, initialRecentPosts] = await Promise.all([
      fetchFeaturedPosts(),
      fetchCategoryPosts(),
      fetchRecentPosts(),
    ])

    return { props: { initialFeaturedPosts, initialRecentPosts, initialCategoryPosts, isMobile } }
  } catch (e) {
    Bugsnag.notify(e)

    return { props: { isMobile } }
  }
}

/**
 * Home
 */

const Home = ({ initialFeaturedPosts = [], initialRecentPosts = [], initialCategoryPosts = [], isMobile }) => {
  const { data: featuredPosts } = useQuery('home-featured', fetchFeaturedPosts, {
    initialData: initialFeaturedPosts,
  })

  const { data: categoryPosts } = useQuery('custom-posts', fetchCategoryPosts, {
    initialData: initialCategoryPosts,
  })

  const { featuredPosts: initialFirstBlock, recentPosts: secondBlock } = slicePosts(initialRecentPosts)

  const { data: firstBlockPosts } = useQuery('first-block-posts', fetchFirstBlockPosts, {
    initialData: initialFirstBlock,
  })

  const firstImage = get(featuredPosts, '[0].images.original')

  return (
    <Layout isMobile={isMobile}>
      <HomeHead image={firstImage} />
      <FeaturedItems data={featuredPosts} showCategory isMobile={isMobile} />
      {isMobile && (
        <StyledRow justify="center">
          <MobileAd name="home-mobile-ad" centered />
        </StyledRow>
      )}
      <StyledContainer>
        <ResponsivePostGrid data={[firstBlockPosts]} />
      </StyledContainer>
      {!isEmpty(categoryPosts) && <CustomCategoryPosts data={categoryPosts} slug={DEFAULT_CUSTOM_CATEGORY_SLUG} />}
      <InfinitePosts initialRecentPosts={secondBlock} fetch={fetchRecentPosts} queryKey="home" />
      <NewsletterSubscribe />
    </Layout>
  )
}

/**
 * PropTypes
 */

Home.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  initialFeaturedPosts: PropTypes.array,
  initialCategoryPosts: PropTypes.array,
  initialRecentPosts: PropTypes.array,
}

/**
 * Styles
 */

const StyledContainer = styled(Container)`
  margin-bottom: 30px;
`

const StyledRow = styled(Row)`
  margin-top: 15px;
`

/**
 * Exports
 */

export default Home
