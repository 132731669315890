import React from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

import BlockItem from '~/components/BlockItem'
import { Routes } from '~/helpers/constants'

/**
 * Custom Category Posts Component
 */

const CustomCategoryPosts = ({ data, slug }) => {
  const title = slug.toUpperCase()

  return (
    <ColoredBackground>
      <Container>
        <Row justify="center">
          <Col md={6} lg={12}>
            <Row>
              <Col md={12}>
                <CategoryContainer>
                  <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/${slug}`} passHref>
                    <CategoryLink>
                      <CategoryTitle>{title}</CategoryTitle>
                    </CategoryLink>
                  </Link>
                  <Link href={`${Routes.CATEGORY}/[category]`} as={`${Routes.CATEGORY}/${slug}`} passHref>
                    <CategoryLink>Ver más</CategoryLink>
                  </Link>
                </CategoryContainer>
              </Col>
            </Row>
            <Row>
              {!isEmpty(data) &&
                data.map(item => (
                  <Col key={item.id} xs={12} sm={12} md={4} lg={3}>
                    <BlockItem item={item} />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </ColoredBackground>
  )
}

/**
 * PropTypes
 */

CustomCategoryPosts.propTypes = {
  data: PropTypes.array,
  slug: PropTypes.string.isRequired,
}

/**
 * Styled Components
 */

const ColoredBackground = styled.div`
  background-color: ${props => props.theme.colors.feature};
  margin-bottom: 20px;
  padding: 20px 0;
`

const CategoryContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const CategoryLink = styled.a`
  display: flex;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
`

const CategoryTitle = styled.h2`
  padding-bottom: 0;
  font-weight: 900;
  border-bottom: 4px solid ${props => props.theme.colors.text};
`

/**
 * Exports
 */

export default CustomCategoryPosts
