import React, { useCallback, useState } from 'react'
import { Col, config, Container, Row } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import styled from 'styled-components'
import * as yup from 'yup'

/**
 * Helpers
 */

const validationSchema = yup.object().shape({
  email: yup.string().email(),
})

/**
 * Subscribe to newsletter component
 */

const NewsletterSubscribe = () => {
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const [isSubscribing, setSubscribing] = useState(false)
  const [hasSubscribed, setSubscribed] = useState(false)
  const [error, setError] = useState(null)

  const handleSubscribePress = useCallback(async values => {
    setSubscribing(true)
    try {
      const body = { email: values.email }
      await fetch('/api/newsletter', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
      })

      setSubscribed(true)
      setSubscribing(false)
    } catch {
      // TODO: Catch error
    }
  }, [])

  return (
    <ColoredBackground>
      <Container>
        <Row justify="center">
          <Col md={6} lg={12}>
            <Row>
              <Col md={12}>
                <ContentContainer>
                  <Title>Suscribite a nuestro newsletter!</Title>
                  <Subtitle>Recibí las mejores noticias de DJ Mag Latinoamérica en tu correo electrónico.</Subtitle>
                  {!hasSubscribed && (
                    <Form onSubmit={handleSubmit(handleSubscribePress)}>
                      <Input
                        disabled={isSubscribing}
                        name="email"
                        type="email"
                        required
                        placeholder="Ingresá tu email"
                        aria-label="Suscribirse a newsletter"
                        ref={register({ required: true })}
                      />
                      <Button type="submit" disabled={isSubscribing} isSubscribing={isSubscribing}>
                        Suscribirme
                      </Button>
                    </Form>
                  )}
                  {hasSubscribed && <Thanks>¡Gracias por suscribirte!</Thanks>}
                  {error && <Error>{error}</Error>}
                </ContentContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </ColoredBackground>
  )
}

/**
 * Styled Components
 */

const ColoredBackground = styled.div`
  background: linear-gradient(
    90deg,
    ${props => props.theme.colors.primary} 0,
    ${props => props.theme.colors.primaryTinted} 100%
  );
  color: ${props => props.theme.colors.textOverImage};
  padding: 20px 0;
`

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0;

  ${props => config(props).media.lg`
    margin: 60px 0;
    align-items: center;
  `}
`

const Title = styled.h1`
  margin: 0 0 10px 0;
  font-size: 32px;
`

const Subtitle = styled.p`
  margin: 0 0 40px 0;
  font-size: 20px;
  font-family: ${props => props.theme.fonts.primary};
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props => config(props).media.lg`
    height: 42px;
    flex-direction: row;
    justify-content: center;
  `}
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  padding: 12px 24px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  ${props => config(props).media.lg`
    width: 320px;
    margin-bottom: 0;
    margin-right: 10px;
  `}
`

const Button = styled.button`
  width: 130px;
  height: 42px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  background-color: ${props => (!props.disabled ? props.theme.colors.background : props.theme.colors.backgroundTinted)};
  color: ${props => props.theme.colors.textOverImage};
  font-size: 18px;
  font-weight: 700;
  box-shadow: none;
  cursor: ${props => (!props.disabled ? 'pointer' : 'unset')};

  &:hover {
    background-color: ${props => props.theme.colors.backgroundTinted};
  }
`

const Error = styled.p`
  position: absolute;
  bottom: -48px;
`

const Thanks = styled.p`
  display: flex;
  font-weight: bold;
  align-items: center;
  height: 42px;
  margin: 0;
`

/**
 * Exports
 */

export default NewsletterSubscribe
