import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { filter, sample } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { fetchAdsList } from '~/api'

/**
 * MobileAd
 */

const MobileAd = ({ name, centered = false }) => {
  const { data } = useQuery(name, fetchAdsList)

  const squareAds = useMemo(() => filter(data, item => item.height === item.width), [data])
  const sampleAd = useMemo(() => sample(squareAds), [squareAds])

  return (
    <MultiSizeContainer centered={centered}>
      {sampleAd && (
        <a href={sampleAd.link}>
          <img alt={sampleAd.title} src={sampleAd.img} height={sampleAd.height} width={sampleAd.width} />
        </a>
      )}
    </MultiSizeContainer>
  )
}

/**
 * PropTypes
 */

MobileAd.propTypes = {
  name: PropTypes.string,
  centered: PropTypes.bool,
}

/**
 * Styles
 */

const MultiSizeContainer = styled.div`
  position: sticky;
  top: 110px;
  width: 300px;
  height: ${props => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => (props.centered ? 'align-self: center' : 'align-self: flex-end')};
`

/**
 * Exports
 */

export default React.memo(MobileAd)
