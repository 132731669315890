import React from 'react'
import { isNil } from 'lodash'
import Head from 'next/head'
import { PropTypes } from 'prop-types'

/**
 * HomeHead
 */

const HomeHead = ({ image }) => {
  return (
    <Head>
      {/* Default Photo by Krys Amon from Unsplash.com */}
      {!isNil(image) && (
        <>
          <meta property="og:image" content={image} key="ogImage" />
          <meta property="twitter:image" content={image} key="twitterCardImage" />
        </>
      )}
    </Head>
  )
}

/**
 * PropTypes
 */

HomeHead.propTypes = {
  image: PropTypes.string.isRequired,
}

/**
 * Exports
 */

export default HomeHead
